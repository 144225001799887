import React from 'react';
import { Helmet } from 'react-helmet';
import FormComponent from '../components/Form/FormComponent';

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>UTMs | Adobe UTM Builder</title>
        <meta name="description" content="Easily build UTM (aka CID) parameters for your Adobe marketing campaigns with our public Adobe Campaign URL Builder. Track your marketing efforts effectively in Adobe Analytics." />
        <meta name="keywords" content="Adobe UTM Builder, Best Adobe UTM Builder, Recommended Adobe UTM Builder, Adobe URL Builder, CID Builder, UTM, Campaign URL Builder, Campaign CID Builder, Adobe Analytics, UTM Parameters, CID Parameters, Adobe UTM, Adobe CID, Public Adobe Campaign URL Builder, Easy Adobe UTM Builder, Easy Adobe CID Builder, Adobe Analytics URL Generator, Adobe Analytics Campaign Tracking, Adobe Analytics UTM Tracking, URL Parameter Builder for Adobe, Adobe Campaign URL Parameters, Adobe Analytics URL Parameters, Adobe UTM Generator, Adobe Campaign Builder, Adobe Analytics Link Tracking, Adobe Analytics Campaign Builder, Adobe Analytics UTM Codes, Adobe Analytics CID Codes, UTM Builder for Adobe Analytics, CID Builder for Adobe Analytics, UTM Code Generator Adobe Analytics, UTM Tagging for Adobe Analytics, UTM Builder Online Adobe, Adobe Analytics Tracking Codes, Adobe Analytics Tracking URLs, Adobe Analytics Campaign URLs, How to Build Adobe Analytics URLs, Adobe Analytics URL Best Practices, Adobe Analytics UTM Best Practices, Adobe Analytics Campaign Link Builder, Create UTM Adobe Analytics, Adobe Analytics URL Tagging, Adobe Analytics Link Builder, Adobe Analytics Campaign URL Generator, Adobe Analytics UTM URL Builder, Adobe Analytics URL Creation, UTM and CID for Adobe Analytics" />
        <meta property="og:title" content="Adobe Campaign UTM Builder" />
        <meta property="og:description" content="Easily build campaign URL parameters for your Adobe campaigns with our Adobe UTM (aka CID) builder. Track your marketing efforts effectively in Adobe Analytics." />
        <meta property="og:type" content="website" />
        {/* Additional tags as required */}
      </Helmet>
      <FormComponent />
      {/* Here, you can add any additional components or elements that should only render when not in an iframe */}
      {/* Example: <SomeComponent /> */}
    </div>
  );
};

export default Home;
