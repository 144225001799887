import React from 'react';
import styles from './UTMContent.module.css';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';

const URLStructure = () => {
  return (
    <Container style={{ padding: '0px 25px 20px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '16px 0', flexWrap: 'wrap' }}>
        <span style={{ marginRight: '8px', fontWeight: 'bold' }}>Form's URL structure:</span>
        <Chip label="Landing Page URL" variant="outlined" style={{ backgroundColor: '#e0f7fa', marginRight: '8px' }} />
        <span>?</span>
        <Chip label="cid=" variant="outlined" style={{ backgroundColor: '#a5d6a7', margin: '0 8px' }} />
        <span>:</span>
        <Chip label="source" variant="outlined" style={{ backgroundColor: '#ffe0b2', margin: '0 8px' }} />
        <span>:</span>
        <Chip label="medium" variant="outlined" style={{ backgroundColor: '#e1bee7', margin: '0 8px' }} />
        <span>:</span>
        <Chip label="objective" variant="outlined" style={{ backgroundColor: '#ffabab', margin: '0 8px' }} />
        <span>:</span>
        <Chip label="campaign_name" variant="outlined" style={{ backgroundColor: '#c8e6c9', margin: '0 8px' }} />
        <span>:</span>
        <Chip label="adset name" variant="outlined" style={{ backgroundColor: '#ffccbc', margin: '0 8px' }} />
        <span>:</span>
        <Chip label="ad name" variant="outlined" style={{ backgroundColor: '#bbdefb' }} />
        <span>:</span>
        <Chip label="date" variant="outlined" style={{ backgroundColor: '#bbdefb' }} />
      </div>
    </Container>
  );
};

const UTMContent = () => {
  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.title}>Adobe Campaign UTM (CID) Parameters Explained</h1>
        <p className={styles.subtitle}>
          This table gives a detailed explanation and example of each Adobe Analytics CID (UTM) campaign parameter. Learn how this best-in-class Adobe Analytics CID builder empowers marketers to create links that adhere to best practices, ensuring precise tracking and measurement of your digital marketing campaign performance directly within{' '}
          <a href="https://business.adobe.com/products/analytics/adobe-analytics.html" target="_blank" rel="noopener noreferrer" className={styles.link}>
            Adobe Analytics
          </a>
          .
        </p>
      </header>

      <URLStructure />

      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>Parameter</th>
            <th className={styles.th}>Required</th>
            <th className={styles.th}>Example</th>
            <th className={styles.th}>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.tr}>
            <td className={styles.td}>
              s.campaign variable
              <div>
                <Chip label="cid=" variant="outlined" style={{ backgroundColor: '#a5d6a7' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Yes</td>
            <td className={styles.td}>cid, ccid, pcid</td>
            <td className={styles.td}>
              This form uses cid as its s.campaign variable but this is customized per company. The s.campaign variable is an Adobe Analytics tracking code that allows Adobe to track and analyze how users interact with your site after clicking on the URL. 
            </td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Campaign Source
              <div>
                <Chip label="source" variant="outlined" style={{ backgroundColor: '#ffe0b2' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Yes</td>
            <td className={styles.td}>google, facebook, instagram</td>
            <td className={styles.td}>Where your traffic is coming from.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Campaign Medium
              <div>
                <Chip label="medium" variant="outlined" style={{ backgroundColor: '#e1bee7' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Yes</td>
            <td className={styles.td}>cpc, banner, display</td>
            <td className={styles.td}>What type of medium the link is used upon.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Campaign Objective
              <div>
                <Chip label="objective" variant="outlined" style={{ backgroundColor: '#ffabab' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Yes</td>
            <td className={styles.td}>awareness (aw), consideration (co), conversion (ko)</td>
            <td className={styles.td}>Identify the category behind the URL.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Campaign
              <div>
                <Chip label="campaign_name" variant="outlined" style={{ backgroundColor: '#c8e6c9' }} />
              </div>
            </td>
            <td className={`${styles.td} ${styles.required}`}>Yes</td>
            <td className={styles.td}>holiday_sale, summer, new_release</td>
            <td className={styles.td}>The specific promotion or strategic campaign for the URL.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Ad Set Name
              <div>
                <Chip label="adset name" variant="outlined" style={{ backgroundColor: '#ffccbc' }} />
              </div>
            </td>
            <td className={styles.td}>No</td>
            <td className={styles.td}>mens_shoes, genz</td>
            <td className={styles.td}>A sub-group or variant within a campaign.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Ad Name
              <div>
                <Chip label="ad name" variant="outlined" style={{ backgroundColor: '#bbdefb' }} />
              </div>
            </td>
            <td className={styles.td}>No</td>
            <td className={styles.td}>blue, family_picture</td>
            <td className={styles.td}>Use clear, descriptive terms for the unique name.</td>
          </tr>
          <tr className={styles.tr}>
            <td className={styles.td}>
              Date
              <div>
                <Chip label="date" variant="outlined" style={{ backgroundColor: '#bbdefb' }} />
              </div>
            </td>
            <td className={styles.td}>No</td>
            <td className={styles.td}>01, january</td>
            <td className={styles.td}>Used to group campaign links by initial publication month.</td>
          </tr>
        </tbody>
      </table>
      <p className={styles.subtitle}>
        To learn more about building and managing Adobe Analytics Campaign URLs, visit{' '}
        <a href="https://utmsmartmanager.com/the-complete-guide-to-using-adobe-analytics-utm-parameters/" target="_blank" rel="noopener noreferrer" className={styles.link}>
          The Complete Guide to Using Adobe Analytics UTM Parameters
        </a>{' '}
        (also known as Adobe UTM link parameters).
      </p>
      <p className={styles.caption}>
      About{' '} 
              <a href="https://utmsmartmanager.com/why-is-utm-smart-manager-best/" target="_blank" rel="noopener noreferrer" className={styles.link}>
          UTM Smart Manager:
          </a>{' '} 
          UTM Smart Manager provides the best campaign UTM form-builder for Fortune 5000 Adobe Analytics marketing teams. With pre-defined naming conventions, enforced taxonomy rules, dependency management, and world-class link tracking, Smart Manager helps Adobe marketers optimize their marketing ROI per activity (ie Campaign URL). If you're an Gooogle analytics marketer, you can also click here for{' '}
              <a href="https://google.utmsmartmanager.com/" target="_blank" rel="noopener noreferrer" className={styles.link}>
          the best Google UTM Builder (or Google Campaign URL).
          </a>
      </p>   
    </section>
  );
};

export default UTMContent;
