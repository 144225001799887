import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardHeader, Typography, Avatar, CircularProgress } from '@mui/material';
import styles from './FormComponent.module.css';
import URLFieldComponent from '../Fields/URLFieldComponent';
import DropdownFieldComponent from '../Fields/DropdownFieldComponent';
import TextFieldComponent from '../Fields/TextFieldComponent';
import UTMGenerator from './UTMGenerator';
import UTMViewer from './UTMViewer';
import UTMContent from './UTMContent';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import { getDropdownOptions, getDropdownDependencies, subscribeToDropdownOptions, subscribeToDropdownDependencies } from '../../utils/firestore';

const FormComponent = () => {
  const isPreRendering = navigator.userAgent === 'ReactSnap';
  const [isLoading, setIsLoading] = useState(true);
  const [landingPageUrl, setLandingPageUrl] = useState('');
  const [dropdownValues, setDropdownValues] = useState({ source: '', medium: '', objective: '', date: '' });
  const [textFieldValue1, setTextFieldValue1] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [dropdownDependencies, setDropdownDependencies] = useState({});
  const mediumDisabledText = 'Please select a source first';
  const objectiveDisabledText = 'Please select a medium first';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedOptions = localStorage.getItem('dropdownOptions');
        const cachedDependencies = localStorage.getItem('dropdownDependencies');

        if (cachedOptions && cachedDependencies) {
          setDropdownOptions(JSON.parse(cachedOptions));
          setDropdownDependencies(JSON.parse(cachedDependencies));
        } else {
          const [options, dependencies] = await Promise.all([
            getDropdownOptions(),
            getDropdownDependencies(),
          ]);
          localStorage.setItem('dropdownOptions', JSON.stringify(options));
          localStorage.setItem('dropdownDependencies', JSON.stringify(dependencies));
          setDropdownOptions(options);
          setDropdownDependencies(dependencies);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!isPreRendering) {
      fetchData();

      const optionsUnsubscribe = subscribeToDropdownOptions((newOptions) => {
        localStorage.setItem('dropdownOptions', JSON.stringify(newOptions));
        setDropdownOptions(newOptions);
      });

      const dependenciesUnsubscribe = subscribeToDropdownDependencies((newDependencies) => {
        localStorage.setItem('dropdownDependencies', JSON.stringify(newDependencies));
        setDropdownDependencies(newDependencies);
      });

      return () => {
        optionsUnsubscribe();
        dependenciesUnsubscribe();
      };
    }
  }, [isPreRendering]);



const handleDropdownChange = (field, value) => {
    const newValue = value ? value.label : '';

    setDropdownValues(prevValues => {
        return { ...prevValues, [field]: newValue };
    });

    const updatedOptions = { ...dropdownOptions };

    if (dropdownDependencies.dynamicDependencies && dropdownDependencies.dynamicDependencies[newValue]) {
        const fieldDependencies = dropdownDependencies.dynamicDependencies[newValue];

        Object.keys(fieldDependencies).forEach(dependentField => {
            updatedOptions[dependentField] = fieldDependencies[dependentField];
        });

        setDropdownOptions(updatedOptions);
    } else {

        // Fetch full list of options for dependent fields
        getDropdownOptions().then(fullOptions => {
            Object.keys(dropdownOptions).forEach(dependentField => {
                if (dependentField !== field) {
                    updatedOptions[dependentField] = fullOptions[dependentField];
                }
            });
            setDropdownOptions(updatedOptions);
        }).catch(error => {
            console.error('Error fetching full list of dropdown options:', error);
        });
    }
};


  // Additional logs for debugging
  useEffect(() => {
  }, [landingPageUrl, dropdownValues]);

  const utmParams = {
    landingPageUrl,
    utm_source: dropdownValues.source,
    utm_medium: dropdownValues.medium,
    utm_objective: dropdownValues.objective,
    utm_campaign: textFieldValue1,
    utm_content: textFieldValue2,
    utm_term: textFieldValue3,
    utm_date: dropdownValues.date,
  };

  if (isLoading) {
    return (
      <Card className={styles.formComponent} sx={{ width: '90%', margin: '0 auto', mt: 1 }}>
        <CardContent>
          <CircularProgress />
          <Typography>Loading...</Typography>
        </CardContent>
      </Card>
    );
  }

    if (isPreRendering) {
    // Render a simplified version for pre-rendering
    return <div>Loading form...</div>;
  }

  return (
    <Card className={styles.formComponent} sx={{ width: '90%', margin: '0 auto', mt: 1 }}>
      <CardHeader
        avatar={
          <Avatar 
            alt="Adobe Analytics UTM builder by UTM Smart Manager" 
            src="/AdobeUTM.png" 
            sx={{ width: 100, height: 100, borderRadius: 3 }}
          />
        }
        title={<Typography variant="h4">Adobe Analytics UTM Builder</Typography>}
        subheader={<Typography variant="body1">Use this campaign UTM builder form for Adobe Analytics best-pratices URL tracking</Typography>}
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <form>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="caption" sx={{ color: 'gray', textAlign: 'center', mt: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
              <span style={{ fontWeight: 'bold' }}>Form settings:</span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <KeyboardCapslockIcon fontSize="inherit" sx={{ color: 'inherit', marginLeft: '4px', marginRight: '4px' }} /> 
                no caps allowed, 
              </span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <SpaceBarIcon fontSize="inherit" sx={{ color: 'inherit', marginLeft: '4px', marginRight: '4px' }} />
                spaces as "-" (hyphens)
              </span>
            </Typography>

            <URLFieldComponent 
              label="Landing page URL" 
              required={true} 
              value={landingPageUrl}
              onChange={setLandingPageUrl}
            />
            <Box display="flex" flexDirection="row" gap={2}>
                <DropdownFieldComponent 
                  label="Source" 
                  options={dropdownOptions.source || []} 
                  dynamicOptions={dropdownDependencies.source && dropdownDependencies.source[dropdownValues.source]}
                  value={{ label: dropdownValues.source }}
                  onChange={(value) => handleDropdownChange('source', value)}
                  required={true}
                  hint="Click to select your source"
                  helperText="The referrer (i.e. facebook, google)"
                  includeOther={true}
                />
                  <DropdownFieldComponent 
                  label="Medium" 
                  options={dropdownOptions.medium || []} 
                  dynamicOptions={dropdownDependencies.medium && dropdownDependencies.medium[dropdownValues.medium]}
                  value={{ label: dropdownValues.medium }}
                  onChange={(value) => handleDropdownChange('medium', value)}
                  required={true}
                  hint={!dropdownValues.source ? mediumDisabledText : "Click to select your medium"}
                  helperText={!dropdownValues.source ? mediumDisabledText : "Marketing medium (i.e. display, video, cpc)"}
                  disabled={!dropdownValues.source}
                />
              <DropdownFieldComponent 
                label="Objective" 
                options={dropdownOptions.objective || []}
                dynamicOptions={dropdownDependencies.objective && dropdownDependencies.objective[dropdownValues.objective]}
                value={{ label:dropdownValues.objective}} 
                onChange={(value) => handleDropdownChange('objective', value)}
                required={true}
                hint={!dropdownValues.medium ? objectiveDisabledText : "Click to select your objective"}
                helperText={!dropdownValues.medium ? objectiveDisabledText : "Abbreviated objective (i.e. awareness)"}
                disabled={!dropdownValues.medium}
              />
            </Box>
            <Box display="flex" flexDirection="row" gap={2}>
              <TextFieldComponent
                label="Campaign Name"
                value={textFieldValue1}
                onChange={setTextFieldValue1}
                required={true}
                hint="Type your campaign name"
                helperText="Unique campaign identifier"
              />
              <TextFieldComponent
                label="Adset Name"
                value={textFieldValue2}
                onChange={setTextFieldValue2}
                hint="Type your Adset name"
                helperText="Group of ads (aka utm content)"
              />
              <TextFieldComponent
                label="Ad Name"
                value={textFieldValue3}
                onChange={setTextFieldValue3}
                hint="Type your ad name"
                helperText="Unique ad name (aka utm term)"
              />
              <DropdownFieldComponent 
                label="Date" 
                options={dropdownOptions.date || []}
                dynamicOptions={dropdownDependencies.date && dropdownDependencies.date[dropdownValues.date]} 
                value={{ label:dropdownValues.date}} 
                onChange={(value) => handleDropdownChange('date', value)}
                hint="Select your release month"
                helperText="Your ad's release month"
              />
            </Box>

            <UTMGenerator utmParams={utmParams}>
              {(utm) => (
                <div className={styles.utmViewerWrapper}>
                  <UTMViewer utm={utm} />
                </div>
              )}
            </UTMGenerator>
          </Box>
        </form>
      </CardContent>
      <UTMContent />
    </Card>
  );
};

export default FormComponent;
