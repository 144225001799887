import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD_uuR1a8uFmE8tOGlk_Ab9oMM5Uq_sgyE",
  authDomain: "adobe-utm-builder.firebaseapp.com",
  projectId: "adobe-utm-builder",
  storageBucket: "adobe-utm-builder.appspot.com",
  messagingSenderId: "708226364416",
  appId: "1:708226364416:web:c40d16ce027ba5043e54e5",
  measurementId: "G-EG0K3Y29N0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };